<template>
  <b-card>
    <b-row>
      <b-col>
        <p>This page allows x7trade Admins to update the HS codes recognized by the EU.
          These codes are used to validate shipments, and any invalid HS codes will trigger a <b>“Invalid HS code”</b>
          warning in the shipment view after the manifest is uploaded.</p>

        <p>Since the EU updates HS codes <b>monthly</b>, it is recommended to perform this update <b>once a month</b>.</p>

        <p>
          <b>Steps to Update HS Codes:</b>
          <ul>
            <li>
              <b>Download the latest valid HS codes in Excel format</b>
              <ul>
                <li>
                  The file is published monthly at:
                  <a href="https://circabc.europa.eu/ui/group/0e5f18c2-4b2f-42e9-aed4-dfe50ae1263b/library/6e7dc94f-70e4-44ed-9560-b9794979cb87" target="_blank">EU CIRCABC Library</a></li>
              </ul>
            </li>
            <li>
              <b>Upload the Excel file</b>
              <ul>
                <li>Use the upload field below to submit the file.</li>
              </ul>
            </li>
            <li>
              <b>Wait for confirmation</b>
              <ul>
                <li>A success message “<b>File successfully uploaded</b>” will appear once the update is complete.</li>
              </ul>
            </li>
          </ul>
        </p>

        <p>
          <b>Important Notes:</b>
          <ul>
            <li>
              The system <b>expects the uploaded Excel file to contain all valid HS codes</b>.
              <ul>
                <li>
                  <b>As a result, this process will:</b>
                  <ul>
                    <li><b>Delete all old HS codes</b> from the database.</li>
                    <li><b>Extract valid HS codes</b> from the column titled “<b>Goods code</b>” in the uploaded Excel.</li>
                    <li><b>Store only the portion of the code before the first space</b>
                      (since the EU list includes suffixes unrelated to the HS code itself).</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-file
          v-if="!upload.isUploading"
          id="attachment-upload-btn"
          v-model="uploadFile"
          placeholder="Select a file"
          drop-placeholder="Drop file here..."
          class="b-custom-control-xl"
          @input="formUploadFile()"
        />
        <b-progress
          v-if="upload.isUploading"
          ref="fileProgress"
          :value="upload.uploadProgress"
          max="100"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile, BProgress,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BProgress,
    BFormFile,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      uploadFile: null,
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      fileBaseUrl: `${process.env.VUE_APP_API_URL}/v1/valid-hs-code`,
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    formUploadFile() {
      this.upload.isUploading = true
      this.upload.uploadProgress = 0

      const formData = new FormData()
      formData.append('file', this.uploadFile, this.uploadFile.name)

      this.$http.post(this.fileBaseUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          this.upload.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
      })
        .then(response => {
          this.upload.isUploading = false
          if (response.data.documentAttachments && response.data.documentAttachments.length > 0) {
            this.input.document_attachments.push(...response.data.documentAttachments)
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully uploaded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          const reader = new FileReader()
          this.logoImage = URL.createObjectURL(this.uploadFile)
          reader.readAsDataURL(this.uploadFile)
        })
        .catch(error => {
          this.upload.isUploading = false
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
</style>
